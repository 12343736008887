<template>
  <router-link
    :class="{
      'item': true,
      'router-link-active': isActive,
    }"
    @click="$emit('onClick', $event)"
   :to="url"
  >
   <slot />
  </router-link>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: 'MenuItemNavigation',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: '',
      required: true,
    }
  },
  emits: ['onClick']
})
</script>
<style lang="sass" scoped>
.item
  position: relative
  display: flex
  align-items: center
  padding: 14px 24px
  min-height: 90px
  width: 220px
  cursor: pointer
  background: #D8E3F6
  //margin-bottom: 2px
  font-size: 18px
  font-weight: 400
  color: #1F252D
  border: 1px solid #fff
  text-decoration: none
.router-link-active
  border: 1px solid #ccc
  color: #012B74
  background: #fff
  &:after
    content: ''
    position: absolute
    height: 100%
    width: 10px
    top: 0
    left: 0
    background: linear-gradient(180deg, #1F49D1 0%, #4CF1BD 100%)
</style>
