

import {defineComponent} from "vue";

export default defineComponent( {
  name: 'ReviewSentRequestDonePage',
  components: {  },
  setup() {
    return {

    }
  },

});
