

import {defineComponent, onMounted, ref} from "vue";
import RequestPartnerNav from '@/components/RequestPartnerNav.vue';
import WhiteBoxMain from '@/components/WhiteBoxMain.vue';
import BaseButton from '@/components/BaseButton.vue';
import SelectInput from '@/components/SelectInput.vue';
import {getPartnerListApi} from '@/api/QuizApi';
import Multiselect from 'vue-multiselect';
import {sendRequestToPartners} from '@/api/partnerReviewApi';
import useModal from '@/hooks/useModal';
import TheModal from '@/components/TheModal.vue';
import LoadingSvg from '@/assets/img/LoadingSvg.vue';
import {notify} from '@kyvg/vue3-notification';

export default defineComponent({
  name: 'SendToPartnerPage',
  components: {
    LoadingSvg,
    TheModal, SelectInput, BaseButton, WhiteBoxMain, RequestPartnerNav, Multiselect},
  setup() {
    const message = ref(`Hello [partner_name] Below we are requesting the following information from Ciena.

1: To review and edit your questions click the link below.
2: After you are done click confirm and submit.
3: This link will expire in the next 12 hours.
4: Your password to view this link is [password]
5: [url]
`);

    const email = ref('');
    const reminder = ref(null);
    const expires = ref(24);

    const confirmModal = useModal(false);
    const successModal = useModal(false);

    const selectedPartners = ref<{ name: string, id: number } | null>(null);
    const partnersOptions = ref<Array<{ name: string, id: number }>>([]);

    onMounted(() => {
      getPartnerListApi().then(data => {
        partnersOptions.value = data.map(quiz => ({id: quiz.id, name: quiz.name}));
      })

    })
    const handleInputChange = (inputValue: string) => {
      message.value = inputValue;
    };

    const onSendNow = () => {
      confirmModal.close()
      if(selectedPartners.value === null) throw new Error('Partner is not selected')

      sendRequestToPartners({
        expire_in: expires.value,
        message: message.value,
        quiz_ids: [selectedPartners.value.id],
        email: email.value,
        reminder: reminder.value,
      }).then(() => {
        successModal.open()
      })
      .catch((error) => {
        let errorStr = error.response.data.message || 'Something wen wrong, please check your mail config' as string;
        notify({
          title: errorStr,
          type: 'error',
          duration: 8000,
          closeOnClick: true,
        });
      });

    }
    return {
      selectedPartners,
      partnersOptions,
      message,
      reminder,
      expires,
      handleInputChange,
      onSendNow,
      confirmModal,
      successModal,
      email,
    }
  },

});
