

import {defineComponent} from 'vue';
import MenuItemNavigation from '@/components/MenuItemNavigation.vue';
export default defineComponent({
  name: 'RequestPartnerNav',
  components: {MenuItemNavigation},
  props: {
    isReviewRequestActive: {
      type: Boolean,
      default: false,
    },
  }
})
